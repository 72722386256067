<template>
  <div>
    <!--Overlay Effect-->
    <div v-if="dialog" class="fixed inset-0 bg-black bg-opacity-70 overflow-y-auto h-full w-full z-50" id="modal-payment">
      <div class="w-full h-full absolute" @click="cancel()"></div>
      <!--modal content-->
      <div class="relative top-1/2 transform -translate-y-1/2 mx-auto max-w-screen-sm bg-white rounded-2xl">
        <div class="pt-8 pb-2 px-12 flex items-center justify-between rounded-t-2xl">
          <div class="flex items-center">
            <img src="@/assets/insurance.svg" class="h-20" alt="" />
            <div class="items-center text-base font-medium ml-9">
              <p class="font-semibold">Personal Accident</p>
              <p>Insurance Non Premium - Rp 10.000/day</p>
              <p>2 Insured Persons</p>
              <p>Coverage date 04 Aug - 10 Aug 2022</p>
            </div>
          </div>
          <button class="flex self-start p-1 justify-center text-xl" @click="cancel()">
            <font-awesome-icon icon="fa-solid fa-x" />
          </button>
        </div>
        <div class="pt-8 pb-9 px-12 rounded-b-2xl">
          <p class="text-base font-semibold mb-2">Insured</p>
          <div
            class="py-2.5 px-5 border border-b4 text-sm font-semibold w-full rounded-lg mb-3 flex items-center justify-between">
            <p>Aank Tree</p>
            <p class="text-medium">NIK 33225459499844</p>
          </div>
          <div
            class="py-2.5 px-5 border border-b4 text-sm font-semibold w-full rounded-lg mb-5 flex items-center justify-between">
            <p>Kurniawan</p>
            <p class="text-medium">NIK 332254594954161</p>
          </div>
          <p class="text-base font-semibold mb-2">Contact Detail</p>
          <div class="pt-2.5 pb-5 px-5 border border-b4 text-sm font-semibold w-full rounded-lg mb-5">
            <p class="pb-2 border-b border-b4">Aank Tree</p>
            <p class="text-b4 mt-2">Mobile Number</p>
            <p class="">+6287832713181</p>
            <p class="text-b4 mt-2">Email</p>
            <p class="">aanktree@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Content End -->
  </div>
</template>

<script>
export default {
  name: 'ModalInsurancePayment',
  components: {
  },
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    open() {
      this.dialog = true
    },
    cancel() {
      this.dialog = false
    },
  }
}
</script>